import React from "react";
import { Link } from "react-router-dom";
import { Button } from "semantic-ui-react";

function Home() {
  return (
    <div>
  

        <div className="py-5">
        <h1 className="text-center">Introducing VaultFi: Revolutionizing DeFi Security</h1>
          <p className="text-center">
            VaultFi is a cutting-edge, multi-chain decentralized liquidity
            locker designed to protect investors from the risks of DeFi rug
            pulls. By allowing developers and teams to lock up ERC-20 based LP
            tokens, VaultFi ensures the integrity of token liquidity, boosting
            investor confidence and legitimacy.
          </p>

          <div className="text-center">
         <Link to='/vault/create-lock'>
         <Button style={{
              backgroundColor: "rgb(18, 184, 18)",
              color: "#fff",
              padding: "10px 30px",
              borderRadius: "0px",
              fontWeight: "bold",
              marginTop: "20px",
            }}>
              Create Now
            </Button>
         </Link>

          </div>
        </div>
        <div className="py-3">
         

          <h3>The Problem: DeFi Rug Pulls</h3>
          <p>
            Decentralized Finance (DeFi) has democratized access to financial
            services, but it also introduced new risks. Rug pulls, where project
            developers abscond with investor funds, have become a significant
            concern. This lack of trust hinders the growth of the DeFi
            ecosystem.
          </p>

          <h3>The Solution: VaultFi</h3>
          <p>
            VaultFi addresses this issue by providing a secure, decentralized
            platform for locking liquidity. By doing so, it:
          </p>

          <p>
            1. Prevents Rug Pulls: Locked LP tokens cannot be withdrawn,
            ensuring investors' funds are safe.
          </p>
          <p>
            2. Boosts Legitimacy: Projects using VaultFi demonstrate a
            commitment to transparency and security, enhancing their reputation.
          </p>

          <p>
            3. Fosters Confidence: Investors can participate in DeFi projects
            with increased trust, driving growth and adoption.
          </p>

          <h3>Key Features</h3>
          <p>
            1. Multi-Chain Support: VaultFi operates on various blockchain
            networks, including Bome Chain, ensuring widespread compatibility.
          </p>

          <p>
            2. ERC-20 Standard: Supports locking of LP tokens based on the
            widely adopted ERC-20 standard.
          </p>
          <p>
            3. Decentralized: VaultFi operates on a decentralized network,
            resistant to censorship and control.
          </p>

          <p>
            4. Secure: Utilizes robust smart contracts to ensure the security of
            locked tokens.
          </p>

          <h3>Benefits for Developers and Teams</h3>
          <p>
            1. Enhanced Credibility: By using VaultFi, projects demonstrate a
            commitment to security and transparency.
          </p>
          <p>
            2. Increased Investor Trust: Locked liquidity instills confidence,
            attracting more investors.
          </p>

          <p>
            3. Improved Token Performance: Secure liquidity boosts token value
            and stability.
          </p>

          <h3>Benefits for Investors</h3>
          <p>
            1. Protected Investments: Locked LP tokens ensure funds are safe
            from rug pulls.
          </p>

          <p>
            2. Increased Confidence: Invest in projects with secure liquidity,
            reducing risk.
          </p>
          <p>
            3. Better Returns: VaultFi's security features contribute to a more
            stable and profitable DeFi ecosystem.
          </p>

          <h3>Building on Bome Chain</h3>
          <p>VaultFi's initial deployment on Bome Chain offers:</p>

          <p>
            1. High Performance: Bome Chain's fast transaction processing
            ensures efficient liquidity locking.
          </p>
          <p>
            2. Low Fees: Reduced costs for transactions and smart contract
            execution.
          </p>

          <p>
            3. Growing Ecosystem: Bome Chain's expanding DeFi ecosystem offers
            new opportunities for growth.
          </p>

          <p>
            VaultFi is a game-changer in DeFi security, providing a
            decentralized, multi-chain liquidity locker that protects investors
            and boosts project legitimacy. By locking LP tokens, VaultFi ensures
            the integrity of token liquidity, fostering a more secure and
            confident DeFi ecosystem. Join the VaultFi revolution and help shape
            the future of decentralized finance.
          </p>
        </div>
    </div>
  );
}

export default Home;
