import React, { useState } from "react";
import { Button, Container, Form } from "semantic-ui-react";
import "../assets/css/home.css";
import {
  useWeb3ModalProvider,
  useWeb3ModalAccount,
} from "@web3modal/ethers/react";
import { factoryABI, FACTORY, lockerABI, tokenAbi } from "../utils";
import { BrowserProvider, Contract, parseEther } from "ethers";
import { notify } from "../utils/notification";
// import NetworkSwitcher from "../components/NetworkSwitcher";

function CreateLock() {
  const { isConnected } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();
  const [loading, setLoading] = useState(false);
  // const [selectedNetwork, setSelectedNetwork] = useState(BOME);

  const [formData, setFormData] = useState({
    tokenAddress: "",
    title: "",
    amount: "",
    unlockDate: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  async function approveToken(amount, tokenAddress, contractAddress, signer) {
    try {
      const TOKEN = new Contract(tokenAddress, tokenAbi, signer);
      const amountInWei = parseEther(amount.toString());
      notify("Approve token in your wallet");
      const tx = await TOKEN.approve(contractAddress, amountInWei);
      await tx.wait();
    } catch (err) {
      console.error("Failed to approve token:", err);
    }
  }

  async function handleCreator(e) {
    setLoading(true);
    e.preventDefault();
    if (!isConnected) {
      notify("Please connect your wallet!");
      return;
    }
    if (!walletProvider) {
      notify(
        "Wallet provider is not available. Please try reconnecting your wallet."
      );
      setLoading(false);
      return;
    }
    const ethersProvider = new BrowserProvider(walletProvider);
    const signer = await ethersProvider.getSigner();
    const { tokenAddress, amount, unlockDate, title } = formData;
    const amountInWei = parseEther(amount.toString());
    try {
      const date = new Date(unlockDate);
      const timestamp = Math.floor(date.getTime() / 1000);
      console.log(timestamp);
      const currentDate = Math.floor(Date.now() / 1000);
      const differenceInSeconds = timestamp - currentDate;
      const isMoreThan3Days = differenceInSeconds > 15 * 24 * 60 * 60;
      if (!isMoreThan3Days) {
        setLoading(false);
        notify("Lock time must be greater than 15days");
        return;
      }

      let newLockerAddress;
      let factoryLocker;

      factoryLocker = new Contract(FACTORY, factoryABI, signer);
      const lockersBefore = await factoryLocker.getAllLockers();
      notify("Transaction sent to your wallet, please confirm...");
      const tx = await factoryLocker.createLocker(tokenAddress);
      await tx.wait();
      const lockersAfter = await factoryLocker.getAllLockers();
      newLockerAddress = lockersAfter.find(
        (locker) => !lockersBefore.includes(locker)
      );

      await approveToken(amount, tokenAddress, newLockerAddress, signer);

      notify("Please do not close this page until transaction is complete");
      const newLock = new Contract(newLockerAddress, lockerABI, signer);
      const locktx = await newLock.create(amountInWei, timestamp, FACTORY, title, {
        value: parseEther("56"),
      });
      await locktx.wait();
      setLoading(false);
      notify("Transactions have been completed");
    } catch (error) {
      console.log(error);
      if (error.code === "ACTION_REJECTED") {
        notify("User rejected the transaction:");
        // Handle the rejection accordingly (e.g., show a notification to the user)
      } else {
        notify("Failed to create lock");
      }
      setLoading(false);
    }
  }

  return (
    <Container>
      <section className="py-3">
        <div>
          <h1>Create Lock</h1>

          {/* <div className="chains">
            <div className="chain__list">
              <h3>EVM Networks</h3>
              <Button onClick={() => switchNetwork(BOME)} disabled={selectedNetwork === BOME}>
                Switch to BOME
              </Button>
              <Button onClick={() => switchNetwork(zeroEvm)} disabled={selectedNetwork === zeroEvm}>
                Switch to Aleph Zero EVM
              </Button>
            </div>
          </div> */}
        </div>
      </section>

      <section className="py-5">
        <div>
          <Form onSubmit={handleCreator}>
            <Form.Field>
              <p>Token or LP-Token address</p>
              <input
                type="text"
                name="tokenAddress"
                value={formData.tokenAddress}
                onChange={handleInputChange}
                placeholder="Ex: 0x..."
                required
              />
            </Form.Field>

            <Form.Field>
              <p>Title</p>
              <input
                type="text"
                name="title"
                value={formData.title}
                onChange={handleInputChange}
                placeholder="Ex: 100"
                required
              />
            </Form.Field>

            <Form.Field>
              <p>Amount</p>
              <input
                type="number"
                step="any"
                name="amount"
                value={formData.amount}
                onChange={handleInputChange}
                placeholder="Enter amount"
                required
              />
            </Form.Field>

            <Form.Field>
              <p>Unlock Date</p>
              <input
                type="datetime-local"
                name="unlockDate"
                value={formData.unlockDate}
                onChange={handleInputChange}
                placeholder="Select date"
                required
              />
            </Form.Field>

            <p>FEE: 56 ZkCRO</p>

            <div className="mt-5">
              <Button
                type="submit"
                loading={loading}
                fluid
                style={{
                  backgroundColor: isConnected ? "#675885" : "gray",
                  color: "#fff",
                }}
                disabled={!isConnected}
              >
                {isConnected ? "Create Lock" : "Please connect your wallet"}
              </Button>
            </div>
          </Form>
        </div>
      </section>
    </Container>
  );
}

export default CreateLock;
