// GlobalStyles.js

import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  body {
    background: #0e0e0e;
    color: #fff;
  }
  p{
    font-size: 1rem;
  }
`;

export default GlobalStyle;
