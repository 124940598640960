import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toLongAddress } from "../utils/formatter";
import { BrowserProvider, Contract, formatUnits } from "ethers";
import {
  useWeb3ModalProvider,
  useWeb3ModalAccount,
} from "@web3modal/ethers/react";
import { Button } from "semantic-ui-react";
import { lockerABI } from "../utils";
import { notify } from "../utils/notification";

function Manage() {
  const { address } = useParams();
  const { isConnected } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();
  const [owner, setOwner] = useState("");
  const [balance, setBalance] = useState(null); // Initialize as null
  const [unlockDate, setUnlockDate] = useState("");
  const [remaininDays, setRemainingDays] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      if (isConnected && walletProvider) {
        try {
          const ethersProvider = new BrowserProvider(walletProvider);
          const signer = await ethersProvider.getSigner();
          const locker = new Contract(address, lockerABI, signer);

          const owner = await locker.owner();
          const balance = await locker.getLockedAmount();
          const unlockDate = await locker.lockEndTime();
          const daysLeft = await locker.getRemainingLockTime();

          setOwner(owner);
          setBalance(balance); // Set the balance
          setUnlockDate(unlockDate.toString());
          console.log(Number(daysLeft));
          setRemainingDays(Number(daysLeft));
        } catch (error) {
          console.error("Error fetching locker:", error);
        }
      }
    };

    fetchData();
  }, [isConnected, walletProvider, address]);

  async function withdraw() {
    setLoading(true);
    if (!isConnected) {
      alert("Please connect your wallet!");
      setLoading(false);
      return;
    }
    if (!walletProvider) {
      alert(
        "Wallet provider is not available. Please try reconnecting your wallet."
      );
      setLoading(false);
      return;
    }
    const ethersProvider = new BrowserProvider(walletProvider);
    const signer = await ethersProvider.getSigner();
    const locker = new Contract(address, lockerABI, signer);

    try {
      const tx = await locker.withdraw();
      await tx.wait();
      notify("Withdraw successful!");
    } catch (error) {
      if (error.reason && error.reason.includes("Lock period has not ended yet")) {
        notify("Cannot withdraw: Lock period has not ended yet");
      } else {
        console.error("Error during withdrawal:", error);
        notify("An unexpected error occurred. Please try again later.");
      }
      console.log(error)
    } finally {
      setLoading(false);
    }
  }

  return (
    <div style={styles.container}>
      <div style={styles.card}>
        <h2 style={styles.title}>Locker Manager</h2>
        <p style={styles.subtitle}>Owner: {toLongAddress(owner)}</p>
        <p style={styles.address}>Vault Address: {toLongAddress(address)}</p>

        <div style={styles.infoContainer}>
          <p style={styles.infoLabel}>Total Locked:</p>
          <p style={styles.infoValue}>
            {balance !== null
              ? `${formatUnits(balance, 18)}`
              : "Loading balance..."}
          </p>
        </div>

        <div style={styles.infoContainer}>
          <p style={styles.infoLabel}>Unlock Date:</p>
          <p style={styles.infoValue}>
            {remaininDays && !isNaN(Number(remaininDays))
              ? new Date(Number(remaininDays) * 1000).toLocaleString()
              : ""}
          </p>
        </div>

        <Button style={styles.button} onClick={withdraw} loading={loading}>
          Withdraw
        </Button>
      </div>
    </div>
  );
}

const styles = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "20px",
  },
  card: {
    backgroundColor: "#fff",
    borderRadius: "10px",
    padding: "30px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    maxWidth: "500px",
    width: "100%",
  },
  title: {
    color: "#333",
    fontSize: "24px",
    fontWeight: "bold",
    marginBottom: "10px",
  },
  subtitle: {
    color: "#777",
    fontSize: "18px",
    marginBottom: "20px",
  },
  address: {
    color: "#555",
    fontSize: "14px",
    marginBottom: "30px",
    wordBreak: "break-all",
  },
  infoContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "15px",
  },
  infoLabel: {
    color: "#666",
    fontSize: "16px",
  },
  infoValue: {
    color: "#000",
    fontSize: "16px",
    fontWeight: "bold",
  },
  button: {
    backgroundColor: "red",
    color: "#fff",
    padding: "12px 24px",
    borderRadius: "5px",
    fontWeight: "bold",
    cursor: "pointer",
    width: "100%",
    textAlign: "center",
    marginTop: "20px",
  },
};

export default Manage;
