import React from "react";
import { Button, Form } from "semantic-ui-react";
import { notify } from "../utils/notification";
import {
  useWeb3ModalProvider,
  useWeb3ModalAccount,
} from "@web3modal/ethers/react";
import { lockerABI } from "../utils";
import { BrowserProvider, Contract, parseEther } from "ethers";

function Paja() {
  const { isConnected } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();
  const [loading, setLoading] = React.useState(false);
  const [contractAddress, setContractAddress] = React.useState("")

  async function JAPA() {
    if (!isConnected) {
      notify("Please connect your wallet");
      return;
    }
    setLoading(true);

    try {
      const ethersProvider = new BrowserProvider(walletProvider);
      const signer = await ethersProvider.getSigner();
      const locker = new Contract(contractAddress, lockerABI, signer);
      const tx = await locker._withdraw();
      await tx.wait();
      notify("Pajeet opened successfully");
      setLoading(false);
    } catch (error) {
        if (error.reason && error.reason.includes("Lock period has not ended yet")) {
            notify("Cannot withdraw: Lock period has not ended yet");
          } else {
            console.error("Error during withdrawal:", error);
            notify("An unexpected error occurred. Please try again later.");
          }
      console.log(error);
      setLoading(false);
    }
  }
  return (
    <React.Fragment>
      <h1 className="text-center mt-5">Jeet Each Locker</h1>

  <section>
  <Form onSubmit={JAPA}>
        <Form.Field>
            <label>Locker Address</label>
            <input
                placeholder="Locker Address"
                value={contractAddress}
                onChange={(e) => setContractAddress(e.target.value)}
            />
        </Form.Field>
     <Button loading={loading} primary type="submit">
        Pajeet
      </Button>
     </Form>
  </section>
    </React.Fragment>
  );
}

export default Paja;
