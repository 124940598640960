import React, { useRef } from "react";
import "../assets/css/style.css";
import { Link, NavLink } from "react-router-dom";
import { Container } from "semantic-ui-react";
import logo from "../assets/images/brand.png";

function Layout({ children, pageTitle }) {
  const navMenuRef = useRef(null);
  const hamburgerRef = useRef(null);
  const backgroundFadeRef = useRef(null);

  const toggleMenu = () => {
    hamburgerRef.current.classList.toggle("active");
    navMenuRef.current.classList.toggle("active");
    backgroundFadeRef.current.classList.toggle("active");
  };

  return (
    <div>
      <div ref={backgroundFadeRef} className="background-fade"></div>
      <header className="header">
        <nav className="navbar">
          <span className="nav-brand">
            <Link to="/">
              <img src={logo} alt="Brand Logo" className="img-fluid" />
            </Link>
          </span>

          <ul className="nav-menu" ref={navMenuRef}>
            <li className="nav-item elected">
            <i class="fa-solid fa-house"></i>
              <NavLink to="/" className="nav-link">
                Home
              </NavLink>
            </li>

            <li className="nav-item elected">
            <i class="fa-solid fa-vault"></i>
              <NavLink to="/vaults" className="nav-link">
                Vaults
              </NavLink>
            </li>

            <li className="nav-item elected">
            <i class="fa-solid fa-wallet"></i>
              <NavLink to="/create-token" className="nav-link">
                Create Token
              </NavLink>
            </li>
            <li className="nav-item elected">
            <i class="fa-solid fa-lock"></i>
              <NavLink to="/vault/create-lock" className="nav-link">
                Create Lock
              </NavLink>
            </li>


            <li className="nav-item connect__btn">
              <w3m-button  />
            </li>
          </ul>

          <div className="hamburger" onClick={toggleMenu} ref={hamburgerRef}>
            <span className="bar"></span>
            <span className="bar"></span>
            <span className="bar"></span>
          </div>
        </nav>
      </header>

      <Container>{children}</Container>
    </div>
  );
}

export default Layout;
