import React from "react";
import "semantic-ui-css/semantic.min.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Layout from "./components/Layout";
import Home from "./pages/Home";
import CreateLock from "./pages/CreateLock";
import GlobalStyle from "./GlobalStyle";
import Vault from "./pages/Vault";
import Manage from "./pages/Manage";
import Interact from "./pages/Interact";
import Paja from "./pages/Paja";
import Token from "./pages/Token";

function App() {
  return (
    <Router>
      <GlobalStyle />
      <Layout>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/create-token" element={<Token />} />
          <Route path="/interact/miner/dapp" element={<Interact />} />
          <Route path="/pajeet/locker/dapp" element={<Paja />} />

          <Route path="/vault/create-lock" element={<CreateLock />} />
          <Route path="/vaults" element={<Vault/>} />
          <Route path="/vault/manage/:address" element={<Manage/>} />
        </Routes>
      </Layout>
    </Router>
  );
}

export default App;
