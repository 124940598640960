import React, { useEffect, useState } from "react";
import { factoryABI, FACTORY, lockerABI } from "../utils";
import { BrowserProvider, Contract } from "ethers";
import {
  useWeb3ModalProvider,
  useWeb3ModalAccount,
} from "@web3modal/ethers/react";
import { Link } from "react-router-dom";
import { toLongAddress } from "../utils/formatter";
import "../assets/css/home.css";

function Vault() {
  const { isConnected } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();

  const [vault, setVault] = useState([]);
  const [lockerNames, setLockerNames] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      if (isConnected && walletProvider) {
        try {
          const ethersProvider = new BrowserProvider(walletProvider);
          const signer = await ethersProvider.getSigner();
          const factoryLocker = new Contract(FACTORY, factoryABI, signer);
          const lockers = await factoryLocker.getAllLockers();

          const names = {};

          for (const e of lockers) {
            const locker = new Contract(e, lockerABI, signer);
            const name = await locker.getLockerName();
            names[e] = name;
          }

          setLockerNames(names);
          console.log(lockers);
          console.log(lockers.length);
          setVault(lockers);
          setLoading(false);
        } catch (error) {
          console.error(error);
          setLoading(false);
        }
      }
    };

    fetchData();
  }, [isConnected, walletProvider]);

  // Handle scroll to load more (for mobile)

  if (!isConnected) {
    return (
      <React.Fragment>
        <h1 className="text-center mt-5">Please connect your wallet</h1>
      </React.Fragment>
    );
  }
  if (loading) {
    return (
      <React.Fragment>
        <h1 className="text-center mt-5">Loading...</h1>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      {vault.length > 0 ? (
        <>
          <h2>Available locks</h2>
          <div className="vault__container">
            {vault.map((vt) => (
              <Link to={`/vault/manage/${vt}`} key={vt} className="vault__item">
                <h3>Project Name: {lockerNames[vt]}</h3>
                <p>Address: {toLongAddress(vt)}</p>
              </Link>
            ))}
          </div>
          <div className="py-5"></div>
        </>
      ) : (
        <>NO LOCKS</>
      )}
    </React.Fragment>
  );
}

export default Vault;
