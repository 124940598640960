import Toastify from "toastify-js";

export const notify = (message) => {
  Toastify({
    text: `${message}`,
    duration: 12000,
    newWindow: true,
    close: true,
    gravity: "top",
    position: "center",
    stopOnFocus: true,
    style: {
      background: "linear-gradient(to right, orange, orange)",
      borderRadius: "10px",
      fontSize: "14px",
      padding: "15px 20px",
      color: "#fff",
      textAlign: "center",
      fontWeight: "bolder",
    },
    onClick: function () {},
  }).showToast();
};
