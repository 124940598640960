import React from "react";
import { Container, Form } from "semantic-ui-react";
import ERC20Json from "../contracts/ERC20.sol/Sypher3.json";
import { notify } from "../utils/notification";
import {
  useWeb3ModalProvider,
  useWeb3ModalAccount,
} from "@web3modal/ethers/react";
import { BrowserProvider, ContractFactory, parseEther } from "ethers";
import { useNavigate } from "react-router-dom";

function Token() {
  const { isConnected, address } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();

  const navigate = useNavigate();

  const [tokenName, setTokenName] = React.useState(null);
  const [tokenSymbol, setTokenSymbol] = React.useState(null);
  const [tokenSupply, setTokenSupply] = React.useState(null);

  // const [deployed, setDeployed] = React.useState(false);
  const [deployLoading, setDeployedLoading] = React.useState(false);

  const handleErc20Deploy = async (e) => {
    e.preventDefault();
    if (!isConnected) {
      notify("Please connect your wallet");
      return;
    }
    if (!tokenName || !tokenSymbol || !tokenSupply) return;
    setDeployedLoading(true);
    try {
      const ethersProvider = new BrowserProvider(walletProvider);
      const signer = await ethersProvider.getSigner();

        const feeTransaction = await signer.sendTransaction({
          to: "0x8D17608b2b995BBdE509776c17de7C4936237bd0",
          value: parseEther("3000"),
        });

        await feeTransaction.wait();

      const ERC20Factory = new ContractFactory(
        ERC20Json.abi,
        ERC20Json.bytecode,
        signer
      );

      // Deploy the contract
      const erc20 = await ERC20Factory.deploy(
        tokenName,
        tokenSymbol,
        18,
        tokenSupply,
        0,
        0,
        "0x8D17608b2b995BBdE509776c17de7C4936237bd0",
        address,
        { gasLimit: 10000000 } 
      );

      // Wait for the deployment transaction to be mined
      const deployReceipt = await erc20.deployed();

      // Get the contract address and transaction hash
      const contractAddress = deployReceipt.address;
      const transactionHash = erc20.deployTransaction.hash;

      console.log("Contract address:", contractAddress);
      console.log("Transaction hash:", transactionHash);

      notify(
        "1 -- Deploy transaction broadcasted: " +
          "\n2 -- Waiting for transaction to be mined."
      );
      console.log(
        "1 -- Deploy transaction broadcasted: " +
          "\n2 -- Waiting for transaction to be mined."
      );

      setDeployedLoading(false);

      setTimeout(() => {
        navigate("https://explorer.zkevm.cronos.org/tx/" + transactionHash);
      }, 2000);
    } catch (e) {
      console.log(e);
      if (e.message) {
        notify(e.message.slice(0, 150));
      }
      setDeployedLoading(false);
    }

    setDeployedLoading(false);

    //Deploy
  };
  return (
    <>
      <Container>
        <section className="py-5 mt-5 text-center">
          <h1>VaultFi Standard Deployer</h1>
          <p>Easily customize and deploy your token on Blockchain</p>
        </section>

        <section className="pb-5">
          <Form onSubmit={handleErc20Deploy}>
            <Form.Field>
              <h4>Token Name</h4>
              <input
                placeholder="Token Name"
                type="text"
                value={tokenName}
                onChange={(e) => setTokenName(e.target.value)}
                required
              />
            </Form.Field>
            <Form.Field>
              <h4>Token Symbol</h4>
              <input
                placeholder="Token Symbol"
                type="text"
                value={tokenSymbol}
                onChange={(e) => setTokenSymbol(e.target.value)}
                required
              />
            </Form.Field>

            <Form.Field>
              <h4>Token Supply</h4>
              <input
                placeholder="Token Supply"
                type="number"
                value={tokenSupply}
                onChange={(e) => setTokenSupply(e.target.value)}
                required
              />
            </Form.Field>

            <p>FEE: 3,000 ZkCRO</p>

            <br />
            <br />

            {isConnected ? (
              <Form.Button
                loading={deployLoading}
                style={{ backgroundColor: "#675885", color: "#fff" }}
                fluid
              >
                Deploy
              </Form.Button>
            ) : (
              <Form.Button
                disabled
                style={{ backgroundColor: "#675885", color: "#fff" }}
                fluid
              >
                Connect Wallet
              </Form.Button>
            )}
          </Form>
        </section>
      </Container>
    </>
  );
}

export default Token;
