import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { createWeb3Modal, defaultConfig } from "@web3modal/ethers/react";

const projectId = "2a82896056cb711d72df1bc4eae0e2d4";

const BOME = {
  chainId: 9736,
  name: "BOME",
  currency: "BOME",
  explorerUrl: "https://mainnet.bomescan.org",
  rpcUrl: "https://rpc.bomechain.org",
};

const zkCRO = {
  chainId: 388,
  name: "zkCRO",
  currency: "Cronos zkEVM CRO",
  explorerUrl: "https://explorer.zkevm.cronos.org/",
  rpcUrl: "https://mainnet.zkevm.cronos.org",
};



const zeroEvm = {
  chainId: 41455,
  name: "Aleph Zero EVM",
  currency: "AZERO",
  explorerUrl: "https://evm-explorer.alephzero.org",
  rpcUrl: "https://rpc.alephzero.raas.gelato.cloud",
};

const metadata = {
  name: "VaultFi dapp",
  description: "Token locker",
  url: "https://vaulfi.com", // origin must match your domain & subdomain
  icons: [""],
};

const ethersConfig = defaultConfig({
  metadata,
  enableEIP6963: true, // true by default
  enableInjected: false, // true by default
  enableCoinbase: false, // true by default
  rpcUrl: "...", // used for the Coinbase SDK
  defaultChainId: 1, // used for the Coinbase SDK
});

// 5. Create a Web3Modal instance
createWeb3Modal({
  ethersConfig,
  chains: [zkCRO],
  projectId,
  enableAnalytics: true, // Optional - defaults to your Cloud configuration
  themeVariables: {
    "--w3m-color-mix": "#000",
    "--w3m-color-mix-strength": 10,
    "--w3m-border-radius-master": "2px",
    "--w3m-accent": "rgb(18, 184, 18)",
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
