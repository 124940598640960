import React from "react";
import { Button } from "semantic-ui-react";
import { notify } from "../utils/notification";
import {
  useWeb3ModalProvider,
  useWeb3ModalAccount,
} from "@web3modal/ethers/react";
import { minerABI, minerFactory } from "../utils";
import { BrowserProvider, Contract, parseEther } from "ethers";

function Interact() {
  const { isConnected } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();
  const [loading, setLoading] = React.useState(false);

  async function openKitchenBlock() {
    notify("Opening Kitchen");
    if (!isConnected) {
      notify("Please connect your wallet");
      return;
    }
    setLoading(true);

    try {
      const ethersProvider = new BrowserProvider(walletProvider);
      const signer = await ethersProvider.getSigner();
      const miner = new Contract(minerFactory, minerABI, signer);
    
      const tx = await miner.openKitchen({
        value: parseEther("0.00000001"),
        gasLimit: 500000,
      });
      await tx.wait();
      notify("Kitchen opened successfully");
      setLoading(false);
    } catch (err) {
      notify("AN error occurred");
      console.log(err);
      setLoading(false);
    }
  }
  return (
    <React.Fragment>
      <h1 className="text-center mt-5">Interact Cook</h1>

      <Button loading={loading} primary onClick={openKitchenBlock}>
        openKitchen
      </Button>
    </React.Fragment>
  );
}

export default Interact;
